.btn {
  -webkit-box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.3);

  &-primary {
    font-weight: bold !important;
  }

  &-primary,
  &-default,
  &-link {
    text-transform: uppercase;

    &.btn-xs {
      text-transform: none;
    }
  }

  &.ui-select-toggle {
    text-transform: none;
  }

  &-link {
    box-shadow: none;
    -webkit-box-shadow: none;

    &.btn-return {
      color: #333;
    }
  }

  &.btn-xxs {
    padding: 0px 5px 6px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 3px;
  }

  &.btn-link.darker-gray {
    color: $darker-gray;
  }
}

.btn-link.disabled {
  color: #333;
  pointer-events: none;
}

.btn-group-xs {
  .btn,
  .btn-xs {
    text-transform: none;
  }
}

.btn-alert-popover {
  background-color: #f2f2f2;
  color: #aaa;
  border: 1px solid #bfbfbf;
  font-weight: 600;
  width: 50px;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

@each $i in $colors {
  .btn {
    &-#{nth($i, 1)},
    &-#{nth($i, 1)}:visited {
      background-color: nth($i, 2);
      color: set-text-color(nth($i, 2));
      border: 1px solid darken(nth($i, 2), 7%);
      font-weight: 600;

      &:hover,
      &:focus {
        border: 1px solid darken(nth($i, 2), 10%) !important;
        background-color: darken(nth($i, 2), 5%) !important;
        color: set-text-color(nth($i, 2)) !important;
      }

      &:active {
        border: 1px solid darken(nth($i, 2), 8%) !important;
        background-color: darken(nth($i, 2), 5%) !important;
      }

      &:active,
      &:focus {
        outline: none !important;
      }
    }

    &-delete,
    &-delete:hover,
    &-delete:focus {
      color: #da2525;
      margin-right: 20px;
    }
  }
}
