@use '@angular/material' as mat;

@import 'assets/styles/variables';
@import 'assets/styles/custom-palette';
@include mat.core();

// Define a custom mixin that takes in the current theme
@mixin user-component-theme($theme) {
  // Parse the theme and create variables for each color in the palette
  $primary: mat.get-theme-color($theme, primary, 500);
  $accent: mat.get-theme-color($theme, accent, 500);
  $warn: mat.get-theme-color($theme, warn, 500);
  $disabled-color: #b0b0b0;

  //links to the custom palette and defines main colors with a global css specificity
  // allows colors to be used in all scss files without @import
  // .scss example: color: var(--accent);
  :root {
    --primary: #{$primary};
    --accent: #{$accent};
    --warn: #{$warn};
    --mat-grey: #7b7b7b;
    --mat-badge-text-size: 14px;
  }

  // Create theme specific styles here
  .mat-mdc-raised-button:not(:disabled).mat-primary {
    color: $white;
    font-weight: 500;
  }

  .mat-mdc-raised-button.second {
    background-color: var(--primary);
    color: $white;

    .mat-ripple-element {
      background-color: (var(--primary), 0.2);
    }
  }

  .mat-mdc-icon-button:disabled,
  .mat-mdc-icon-button[disabled] {
    opacity: 0.3 !important;
  }

  mat-snack-bar-container {
    &.error {
      .mdc-snackbar__surface {
        background-color: var(--warn);

        .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
          color: white;
        }
      }
    }
  }
}
