#recipeCalc {
  .bionik-calc-header {
    background-color: rgba(139, 195, 74, 0.6);
    text-align: center;
  }
  .nav-tabs {
    margin-bottom: 0;
    border-bottom: 1px solid #5d5d5d;
  }
  .tab-content {
    background-color: #5d5d5d;
  }
  .panel {
    margin-bottom: 0px;
    border: none;
  }
  .panel-heading {
    background-color: #5d5d5d;
  }
  .panel-body {
    border: none;
    margin-bottom: 0px;
  }
  .ps-modal-body {
    width: 100%;
    padding: 0 10px;
  }
  .recipe-info-header {
    margin: 0;
  }
  .recipe-info-table {
    th {
      border-color: transparent;
      font-size: 12px;
      color: #555;
      padding-top: 2px;
      padding-bottom: 2px;
      background-color: #eee;
      text-align: center;
    }
    td {
      border-color: transparent;
      color: #555;
      padding-top: 2px;
      padding-bottom: 2px;
      text-align: center;
    }
  }
  .recipe-info-container {
    padding: 5px;
    .panel-heading {
      background-color: #ecf6fb;
      .panel-title {
        color: #777;
      }
    }
  }
  .male-info-container {
    padding: 5px;
    .panel-heading {
      background-color: #ecf6fb;
      .panel-title {
        color: #555;
      }
    }
    td {
      background-color: #fff;
      color: #777;
      font-weight: 600;
      border-color: #ccc;
    }
    td:first-child {
      background-color: #ecf6fb;
      color: #555;
    }
    thead > tr > th:first-child {
      width: 150px;
    }
    tr:last-child > td:first-child {
      background-color: #fff;
    }
    tr:last-child > td:not(:first-child) {
      background-color: #fcf8e3;
      vertical-align: middle;
    }
  }

  .female-info-container {
    padding: 5px;
    .panel-heading {
      background-color: #fef2f6;
      .panel-title {
        color: #555;
      }
    }
    td {
      background-color: #fff;
      color: #777;
      font-weight: 600;
      border-color: #ccc;
    }
    td:first-child {
      background-color: #fef2f6;
      color: #555;
    }
    thead > tr > th:first-child {
      width: 150px;
    }
    tr:last-child > td:first-child {
      background-color: #fff;
    }
    tr:last-child > td:not(:first-child) {
      background-color: #fcf8e3;
      vertical-align: middle;
    }
  }

  #maleDecision {
    tr:last-child {
      > td:nth-child(2) {
        text-align: right;
        padding-right: 10px;
      }
      > td:nth-child(3) {
        background-color: #fcf8e3;
        vertical-align: middle;
        color: #777;
      }
    }
  }
  .batch-info-container {
    padding: 0;
  }
  .table-info {
    th {
      border-color: transparent;
      font-size: 12px;
      color: #555;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    td {
      border-color: transparent;
      color: #555;
    }
  }
  .table-stagger-def {
    thead > tr {
      background-color: #fff;
    }
    th {
      border-color: transparent;
      font-size: 12px;
      color: #555;
      background-color: #f2f2f2;
      text-align: center;
    }

    td {
      border-color: transparent;
      color: #555;
      text-align: center;
    }
    .bionik-header {
      background-color: rgba(139, 195, 74, 0.4);
      text-align: center;
    }
    .bionik-cell {
      background-color: rgba(139, 195, 74, 0.1);
    }
  }

  .ps-modal .ps-modal-content {
    max-width: 98%;
  }
  .panel-stagger {
    border: none;
  }
  .summary-header {
    background-color: #bfbfbf;
    text-align: center;
    border-radius: 0;
    border: none;
    padding: 5px;
    text-transform: uppercase;

    label {
      color: #555;
      font-size: 14px;
    }
  }

  .calc-notes-area {
    height: 240px;
    width: 100%;
    resize: none !important;
    overflow: auto;
    border-radius: 0;
  }

  .selector-container {
    color: #fff;
    label {
      margin-top: 0;
      margin-bottom: 0;
    }
    span {
      margin-left: 10px;
      margin-right: 10px;
      color: #bfbfbf;
      font-size: 16pt;
    }
  }
  .btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
  }

  .btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
  }
  .horizontal-collapse {
    height: 70px;
  }
  .summary-column {
    vertical-align: middle;
    border-right: 1px solid #555;
    border-left: 1px solid #555;
  }
}
