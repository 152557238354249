@import 'assets/styles/variables';
@import 'assets/styles/style2.scss';
html,
body {
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
figure,
figcaption,
table,
input,
select,
textarea {
  font-family: $font-family !important;
}

.mat-mdc-tab-label,
.mat-mdc-tab-link {
  font-family: $font-family !important;
}

ps-app-root,
ps-layout {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: block;
}

.mat-sidenav-content {
  overflow: hidden !important;
}

//corrects primary tab label transparency
.mat-mdc-tab-link {
  opacity: 1 !important;
}

.host {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.border {
  border: 1px solid #ddd;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center !important;
}

.becks-snackbar {
  .mat-simple-snackbar-action {
    color: white;
  }
  &.warn {
    background-color: $yellow-400;
    color: $gray-700;
    .mat-simple-snackbar-action {
      color: $gray-700;
    }
  }
}

.border-left {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

.side-borders {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.marg-left-4 {
  margin-left: 4px;
}

.marg-right-4 {
  margin-right: 4px;
}

.marg-left-8 {
  margin-left: 8px;
}

.mar-left-16 {
  margin-left: 16px;
}

.marg-right-8 {
  margin-right: 8px;
}

.mar-right-16 {
  margin-right: 16px;
}

.pad-top-8 {
  padding-top: 8px;
}

.pad-top-16 {
  padding-top: 16px;
}

.pad-top-32 {
  padding-top: 32px;
}

.pad-bottom-8 {
  padding-bottom: 8px;
}

.pad-bottom-16 {
  padding-bottom: 16px;
}

.pad-bottom-32 {
  padding-bottom: 32px;
}

.pad-right-4 {
  padding-right: 4px;
}

.pad-right-8 {
  padding-right: 8px;
}

.pad-right-16 {
  padding-right: 16px;
}

.pad-left-4 {
  padding-left: 4px;
}

.pad-left-8 {
  padding-left: 8px;
}

.pad-left-16 {
  padding-left: 16px;
}

.wrapper-remove {
  margin-bottom: -1.34375em !important;
}

.narrow-spacer {
  flex: 0.05 0.05 auto;
}

.wide-spacer {
  flex: 1 1 auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.width-50 {
  width: 50%;
}
.text-left {
  text-align: left !important;

  .tooltip-inner {
    text-align: left;
  }
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

//removes chrome-induced blue background on autofilled inputs
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-transition-delay: 99999s;
}
.ps-table-hover > tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

mat-icon.mat-icon.add {
  font-size: 24px;
  height: 24px;
  color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: inline-block;
}

.mat-grey {
  color: var(--mat-grey);
}

.mat-elevation-zcustom {
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.01),
    0px 4px 8px rgba(0, 0, 0, 0.02),
    0px 1px 12px rgba(0, 0, 0, 0.12);
}

.mat-dialog-container {
  @include breakpoint('tw-sm') {
    margin: 65px 30px 0 64px;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.lt-dialog .mat-mdc-dialog-container {
  padding: 0;
}
