@mixin transition($prop: all, $time: 0.25s, $ease: ease, $ease-time: 0s) {
    -webkit-transition: $prop $time $ease $ease-time;
    -moz-transition: $prop $time $ease $ease-time;
    -o-transition: $prop $time $ease $ease-time;
    transition: $prop $time $ease $ease-time;
}

@mixin border-radius-select($tl: 4px, $tr: 4px, $br: 4px, $bl: 4px) {
    -webkit-border-radius: $tl $tr $br $bl;
    -moz-border-radius: $tl $tr $br $bl;
    border-radius: $tl $tr $br $bl;
}

@mixin border-radius($radius: 4px, $important:false) {
    @if not $important {
        -webkit-border-radius: $radius;
        -moz-border-radius: $radius;
        border-radius: $radius;
    }
    @else {
        -webkit-border-radius: $radius !important;
        -moz-border-radius: $radius !important;
        border-radius: $radius !important;
    }
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    }
    @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin linear-gradient($top: #f2f2f2, $bottom: #eaeaea, $topPos: 0%, $bottomPos: 100%) {
    background: $top;
    /* Old browsers */
    background: -moz-linear-gradient(top, $top $topPos, $bottom $bottomPos);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop($topPos, $top), color-stop($bottomPos, $bottom));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $top $topPos, $bottom $bottomPos);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $top $topPos, $bottom $bottomPos);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $top $topPos, $bottom $bottomPos);
    /* IE10+ */
    background: linear-gradient(to bottom, $top $topPos, $bottom $bottomPos);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$top, endColorstr=$bottom, GradientType=0);
    /* IE6-9 */
}

@function set-text-color($color) {
    @if (lightness($color) > 55) {
        @return #222; // text on lighter background
    }
    @else {
        @return #fff; // text on darker background
    }
}

@mixin rotate($deg:180deg) {
    -ms-transform: rotate($deg);
    -webkit-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin breakpoint($point) {
    @if $point==xl {
        @media screen and (min-width: 1920px) {
            @content ;
        }
    }
    @else if $point==gt-lg {
        @media screen and (min-width: 1920px) {
            @content ;
        }
    }
    @else if $point==lg {
        @media screen and (min-width: 1280px) and (max-width: 1919px) {
            @content ;
        }
    }
    @else if $point==gt-md {
        @media screen and (min-width: 1280px) {
            @content ;
        }
    }
    @else if $point==md {
        @media screen and (min-width: 960px) and (max-width: 1279px) {
            @content ;
        }
    }
    @else if $point==lt-md {
        @media screen and (max-width: 960px) {
            @content ;
        }
    }
    @else if $point==gt-sm {
        @media screen and (min-width: 960px) {
            @content ;
        }
    }
    @else if $point==ipad-landscape {
        @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
            @content ;
        }
    }
    @else if $point==sm {
        @media screen and (min-width: 600px) and (max-width: 959px) {
            @content ;
        }
    }
    @else if $point==gt-xs {
        @media screen and (min-width: 600px) {
            @content ;
        }
    }
    @else if $point==xs {
        // iPhone 7+/8+ //
        @media screen and (max-width: 599px) {
            @content ;
        }
    }
    @else if $point==xs-landscape {
        // use for iphone 6/7+ landscape
        @media screen and (min-width: 668px) and (max-width: 736px) and (orientation: landscape) {
            @content ;
        }
    }
    @else if $point==xxs-landscape {
        // use for iphone 6/7 landscape
        @media screen and (min-width: 569px) and (max-width: 667px) and (orientation: landscape) {
            @content ;
        }
    }
    @else if $point==xxxs-landscape {
        // use for iphone 5 landscape
        @media screen and (min-width: 558px) and (max-width: 568px) and (orientation: landscape) {
            @content ;
        }
    }
    @else if $point==xxs {
        // iPhone 7/8 //
        @media screen and (max-width: 375px) {
            @content ;
        }
    }
    @else if $point==xxxs {
        // iPhone 5 //
        @media screen and (max-width: 320px) {
            @content ;
        }
    }
}
