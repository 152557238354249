@import 'bionik/fieldlist';
@import 'bionik/treatmentinfo';
@import 'bionik/recipecalc';

#bionik {
  height: calc(100vh - 128px);
  width: 100%;
  position: relative;

  #bionikRecipes {
    overflow: hidden;

    .panel-body {
      overflow: auto;
      height: 80vh;
    }

    table {
      border-collapse: collapse;

      th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: #fff;
        border-bottom: 2px solid #ddd;
      }
    }
  }

  .errors {
    font-size: smaller;
    color: red;
  }
  .error-input,
  .error-input:focus {
    outline: none;
    border-color: red;
    box-shadow: 0 0 10px red;
  }

  .header-row {
    margin-bottom: 0px;
  }
  .header-element {
    margin: 0px;
    text-align: center;
  }

  .batch-table {
    font-size: 12px;
    margin-bottom: 0px;
    thead > tr > th {
      text-align: center;
      color: #555;
      width: 70px;
    }
    tbody > tr > td {
      text-align: center;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 600;
      width: 70px;
    }
  }
  .panel-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .recipe-bags-label {
    h4 {
      font-weight: 600;
    }

    .badge {
      color: #337ab7;
      background: white;
    }
  }

  .sticky-header {
    display: block;
    position: sticky;
    margin-top: 0;
    background: #ddd;
    top: 0;
    padding: 5px;
  }

  .ugRateColumn {
    display: block;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    margin: 0px;
  }

  .noOpsCell {
    background-color: #eee !important;
  }
  .opsCell {
    background-color: transparent !important;
  }
  .bags-popover {
    max-width: 200px;
    .popover-content {
      white-space: normal;
      padding: 5px;
    }
  }

  .treatment-type-UTC {
    background-color: rgba(158, 158, 158, 0.05);
    border-color: #eee;
  }
  .treatment-type-1X {
    background-color: rgba(103, 58, 183, 0.05);
    border-color: #eee;
  }
  .treatment-type-2X {
    background-color: rgba(255, 235, 59, 0.05);
    border-color: #eee;
  }
  .treatment-type-3X {
    background-color: rgba(255, 0, 255, 0.05);
    border-color: #eee;
  }
  .treatment-type-HH {
    background-color: rgba(3, 169, 244, 0.05);
    border-color: #eee;
    a {
      background-color: none;
    }
  }
  .treatment-type-SP {
    background-color: rgba(255, 0, 0, 0.05);
    border-color: #eee;
  }
  .treatment-type-SK {
    background-color: rgba(139, 195, 74, 0.05);
    border-color: #eee;
  }

  .warning-indicators {
    display: inline;

    &.has-inventory-updated {
      color: orange;
    }
    &.has-inventory-deleted {
      color: red;
    }
  }

  .alert-acceptance {
    &.inv-update-panel-background {
      background: rgba(255, 165, 0, 0.67);
      padding: 5px;
      color: #4c4c4c;
    }
    &.inv-delete-panel-background {
      color: #fff;
      background: rgba(251, 91, 79, 0.9);
      padding: 5px;
      position: absolute;
      z-index: 999;
      width: 74%;
      height: 50px;
      margin-top: -10px;
      margin-left: -5px;

      &.shadow {
        -webkit-box-shadow: 0 10px 6px -6px #777;
        -moz-box-shadow: 0 10px 6px -6px #777;
        box-shadow: 0 10px 6px -6px #777;
      }
    }
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .alert-popover {
    max-width: 300px;
    border-radius: 0px;
    border: none;
    z-index: 999;
    .popover-content {
      white-space: normal;
      padding: 0px;
    }
  }
  .alert-overlay {
    display: none;
    background-color: Silver;
    text-align: center;
    position: absolute;
    z-index: 10000;
    opacity: 0.5;
  }
  .change-popover {
    min-width: 400px;
    border-radius: 0px;
    border: none;
    z-index: 999;
    .popover-content {
      white-space: normal;
      padding: 5px;
    }
  }
  .extra-padding {
    padding-top: 5px;
  }

  .icon-button {
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
  }

  .total-bags {
    white-space: nowrap;
    margin-top: 20px;
    padding: 0 10px;
    background: #8bc34a;
    text-align: center;
    height: 58px;

    h4 {
      margin-top: 8px;
      font-weight: 600;
      color: #fff;
    }
  }
}
