#fieldList {
  .panel-body {
    padding: 0px;
    max-height: calc(100vh - 180px);
    overflow: auto;
    margin-bottom: 20px;
    border: 1px solid #bfbfbf;
    position: relative;
  }

  table {
    margin-bottom: 0px;
  }

  span.icon-list {
    margin-left: -3px;
    margin-top: 5px;
    display: block;
  }
  .primary-heading > thead > tr > th {
    font-size: 0.8em;
    font-weight: 600;
    border-bottom: 0;
    letter-spacing: 1px;
    vertical-align: top;
    background: #bcbcbc;
    text-transform: uppercase;
    color: #15140e;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .row-header-column {
    vertical-align: middle;
    font-weight: 600;
    background: rgba(242, 242, 242, 0.34);
  }
  .primary-heading > tbody > tr > td:first-child > i {
    color: gray;
    cursor: pointer;
  }
  .sub-heading > thead > tr > th {
    font-size: 0.8em;
    font-weight: 600;
    border-bottom: 0;
    letter-spacing: 1px;
    vertical-align: top;
    background: #ddd;
    text-transform: uppercase;
    color: #4c4c4c;
  }
  .sub-heading > thead > tr > th:first-child {
    width: 110px;
  }
  .male-heading {
    background: #ecf6fb;
    vertical-align: middle;
    color: #777;
  }
  .male-text {
    color: #0079be;
    font-weight: bold;
  }
  .female-heading {
    background: #fef2f6;
    vertical-align: middle;
    color: #777;
  }
  .female-text {
    color: #ff5d7a;
    font-weight: bold;
  }
  .fertility-group-header {
    color: #fff;
    vertical-align: middle;
    text-align: center;
    width: 70px;
  }

  .label-pop {
    border-left: 1px solid #ccc;
    vertical-align: middle;
    text-align: left;
    padding-left: 10px;
  }
  .no-plantings-label {
    text-align: center;
    label {
      color: #888;
      font-style: italic;
    }
  }
  .bags-input {
    width: 70%;
    display: inline-block;
  }
  .input-group {
    width: 100%;
  }
  .decision-pop {
    width: 80%;
    display: inline-block;
  }

  .bags-calc-popover {
    max-width: 100%;
    border-radius: 0px;
    border: none;
    z-index: 1000;
    .popover-content {
      white-space: normal;
      padding: 0px;
    }
  }

  .bags-calc-header-row {
    margin: 0;
    padding: 5px;
    input {
      width: 100%;
    }
  }
  .table-calculator-summary {
    tr:last-child {
      td.total {
        background: rgba(255, 193, 7, 0.42);
        color: #777;
      }
    }
  }
  .btn-male-selector {
    background: lightblue;
    color: #777;
    width: 80px;
    &:hover {
      color: #fff;
      background: rgb(48, 185, 231);
    }
    &:active:focus {
      background: rgb(48, 185, 231);
      color: #777;
    }
    &.active {
      background: rgb(48, 185, 231);
      font-weight: 600;
      color: #fff;
    }
    &.inactive {
      background-color: #888;
      color: #fff;
    }
  }
  .btn-female-selector {
    background: pink;
    color: #777;
    width: 80px;
    &:hover {
      color: #fff;
      background: rgb(248, 93, 118);
    }
    &.active {
      color: #fff;
      font-weight: 600;
      background: rgb(248, 93, 118);
    }
    &.inactive {
      background-color: #888;
      color: #fff;
    }
  }

  .bags-subtotal-container {
    color: #ffeb21;
    text-align: right;
  }

  .bags-total-container {
    label {
      font-size: 18px;
      font-weight: 600;
      color: #f2f2f2;
      padding-right: 10px;
    }
    strong {
      color: #8bc341;
      font-size: 18px;
    }
  }

  .decision-total-container {
    label {
      color: #f2f2f2;
      font-size: 18px;
      font-weight: 600;
      padding-right: 10px;
    }
    strong {
      color: #8bc341;
      font-size: 18px;
    }
  }
  .driver-label {
    font-size: 16px;
    font-weight: 600;
    padding-right: 10px;
  }
}
