$black: #000;
$offwhite: #f0f0f0;
$white: #fff;
$temp: rgba(255, 255, 255, 0.2);
$navbarbg: #f7f7f7;
$background: #e8e8e8;
$mediumgray: #bcbcbc;
$lightgray: #e8e8e8;

///side navbar////
$sidebgbar: #222222;
$darkest: #2b2c31;
$lightest: #383c45;
$active: #4f4f4f;
$border: #24272e;
$homebg: #2dd6cb;
///////text /////////

$graytext: #99a1a6;

////tofixerrors/////
$red: #c62828;
$pink: #f06292;
$purple: #9c27b0;
$deep-purple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$brown: #795548;
$light-brown: #d7ccc8;
$light-blue: #03a9f4;
$cyan: #00bcd4;
$lime: #cddc39;
$dark-green: #388e3c;
$green: #4caf50;
$light-green: #8bc34a;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$light-gray: #eee;
$gray: #ccc;
$medium-gray: #aaa;
$dark-gray: #888;
$darker-gray: #444;
$info: #5bc0de;
$gray-blue: #607d8b;
$muted-blue: #97bbcd;
$muted-yellow: #e3d176;
$muted-yellow-green: #bec474;
$muted-green: #99b872;
$muted-dark-green: #7c9a7e;
$muted-dark-blue: #607d8b;

// Tailwind colors - https://tailwindcss.com/docs/customizing-colors
$yellow-400: #fbbf24;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;

//$primary:#2196f3;
//$primaryhvr:#1e88e5;
//$secondary:#418E8E;

$primary: $light-green;
$primaryhvr: #d8e472;
$secondary: #418e8e;
$default: $white;

$colors: 'gray' $gray, 'light-gray' $light-gray, 'darker-gray' $darker-gray, 'dark-gray' $dark-gray,
  'medium-gray' $medium-gray, 'white' $white, 'black' $black, 'red' $red, 'pink' $pink,
  'purple' $purple, 'deep-purple' $deep-purple, 'indigo' $indigo, 'blue' $blue,
  'light-blue' $light-blue, 'brown' $brown, 'light-brown' $light-brown, 'cyan' $cyan, 'lime' $lime,
  'dark-green' $dark-green, 'green' $green, 'light-green' $light-green, 'yellow' $yellow,
  'amber' $amber, 'orange' $orange, 'primary' $primary, 'info' $info, 'gray-blue' $gray-blue,
  'muted-blue' $muted-blue, 'muted-yellow' $muted-yellow, 'muted-yellow-green' $muted-yellow-green,
  'muted-green' $muted-green, 'muted-dark-green' $muted-dark-green,
  'muted-dark-blue' $muted-dark-blue;

//toggle switch
$greyoutline: #e6e6e6;
$bluebtn: #0055cc;
$bluecontrast: #0088cc;

//Update Prod Forecast
$switchOffColor: #ccc;
$switchOnColor: #ccdcb8; //#8BC34A;
$switchBorderRadius: 1em;

//Seed Needs Status
$delivered: #79af3a;
$assigned: #454545;
$planned: #a7a7a7;
$remaining: #eeeeee;
$exceeded: #417108;
$growersupply: #96c6ef;
$restricted: #f5c9cf;
$reservedruns: #9f74d1;
$reservedwholesale: #6237d2;

//Run plan status
$run-draft: #ccc;
$run-planned: #ffcf0b;
$run-instruction: #bee4ff;
$run-sourcing: #64c0ff;
$run-summary: #004e9b;
$run-generated: #002c60;
$run-delivered: #ceedab;
$run-finished: $primary;
$ws-draft: #ccc;
$ws-request: #d7bbcd;
$ws-sourcing: #b66396;
$ws-contract: #8e095b;
$ws-generated: #520033;
$ws-finished: $primary;
$canceled: #d0021b;
