/* You can add global styles to this file, and also import other style files */
/* Importing this is causing issues with layout due to other resets: @import 'tailwindcss/base'; */
@use '@angular/material' as mat;
@include mat.core();
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'assets/styles/variables';
@import 'production-suite-app-theme';

$production-suite-app-primary: mat.m2-define-palette($production-suite-primary);
$production-suite-app-accent: mat.m2-define-palette($production-suite-accent);
$production-suite-app-warn: mat.m2-define-palette($production-suite-warn);
$production-suite-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $production-suite-app-primary,
      accent: $production-suite-app-accent,
      warn: $production-suite-app-warn
    )
  )
);
@include mat.all-component-themes($production-suite-app-theme);

// Pass the theme variable to the mixin

@include user-component-theme($production-suite-app-theme);

/* Using this fixes Tailwind REMs, but breaks REMs elsewhere in the app.*/
html {
  font-size: 14px !important;
}

.mat-toolbar-row {
  h2,
  h3 {
    font-weight: normal;
  }
}

.mat-toolbar {
  z-index: 999;
  position: relative;
  background: $gray-6-opacity;
  color: $white;
  font-weight: 600;
}

a {
  color: #2e63b7;
  text-decoration: none;
  cursor: pointer;
}

a :visited {
  color: #2e63b7;
}

// SLIDE-UP
// use this class on material cards that are being treated as slide-ups.  Example: https://projects.invisionapp.com/d/main#/console/13160614/275163056/preview
.mat-mdc-card.slide-up {
  bottom: 0;
  display: block;
  left: 60px;
  position: absolute;
  overflow: scroll;
  padding: 18px;
  right: 0;
  top: 128px;
  transition: left 400ms ease-in-out;
  z-index: 1000;
}

.clickable {
  cursor: pointer;
}

.hoverable {
  cursor: default;
}

.nav-menu-open .mat-mdc-card.slide-up {
  left: 250px;
}

// SLIDE-UP END
.mat-mdc-form-field-infix {
  width: auto !important;
}

.mat-mdc-form-field.queue-status {
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0;

    .mat-mdc-form-field-outline {
      color: #fff !important;
    }

    .mat-mdc-form-field-infix {
      padding: 5px 0;
      width: 2.5em !important;
      text-align: center;

      .mat-mdc-input-element {
        color: #fff;
        font-weight: 700;
      }

      .mat-mdc-form-field-label-wrapper {
        .mat-mdc-form-field-label {
          font-size: 0.9em;
          text-overflow: initial;
          color: #fff !important;
        }
      }
    }
  }
}

.mat-icon-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mat-mdc-text-field-wrapper label {
  font-weight: 500;
}

.mat-dialog-container {
  background-color: white;

  h1.mat-dialog-title {
    font:
      500 20px / 32px Roboto,
      'Helvetica Neue',
      sans-serif;
    letter-spacing: normal;
  }
}

.mat-button-base,
.mat-mdc-button-base {
  font-weight: 500;
}

.mat-mdc-card {
  padding: 16px;
}

.mat-mdc-form-field-type-mat-chip-list .mat-mdc-text-field-wrapper,
.mat-mdc-form-field-type-mat-chip-list .mat-mdc-form-field-focus-overlay {
  background-color: transparent;
  border-bottom: none;
}

.mat-mdc-raised-button:not(:disabled).mat-primary {
  color: $white;
  font-weight: 500;
}

.mat-mdc-raised-button.second {
  background-color: #79af3a;
  color: $white;
}

.mat-mdc-icon-button:disabled,
.mat-mdc-icon-button[disabled] {
  opacity: 0.3 !important;
}

mat-snack-bar-container {
  &.error {
    .mdc-snackbar__surface {
      background-color: #b73831;

      .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
        color: white;
      }
    }
  }
}
