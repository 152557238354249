@import 'mixins';
@import 'shared-colors';
@import 'buttons';
@import 'tables';
@import 'bionik';

.white {
  color: #fff;
}

.green {
  color: $primary;
}

i {
  &.fa-flask {
    color: $red;
    margin-left: 3px;
  }

  &.icon-fertile,
  &.icon-sterile,
  &.icon-fertile-sterile,
  &.icon-seedstock,
  &.icon-retail {
    margin-right: 2px;
    font-size: 10px;
    color: #fff;
    height: 14px;
    width: auto;
    padding: 0 5px 0 3px;
    @include border-radius(2px);
    display: inline-block;
    font-weight: normal;
    text-align: center;

    &::before {
      line-height: 14px;
      text-shadow: 1px 1px 3px rgba(#000, 0.4);
    }
  }

  &.icon-fertile {
    background-color: $blue;

    &.icon-large {
      font-size: 16px;
      height: 24px;
      width: 24px;
      padding-top: 1px;
    }

    &::before {
      content: 'F';
    }
  }

  &.icon-sterile {
    background-color: $orange;

    &.icon-large {
      font-size: 16px;
      height: 24px;
      width: 24px;
      padding-top: 1px;
    }

    &::before {
      content: 'S';
    }
  }

  &.icon-fertile-sterile {
    background-color: $dark-green;

    &.icon-large {
      font-size: 16px;
      height: 24px;
      padding-top: 1px;
    }

    &::before {
      content: 'F/S';
    }
  }

  &.icon-seedstock {
    background-color: $lightest;

    &.icon-large {
      font-size: 16px;
      height: 24px;
      width: 24px;
      padding-top: 1px;
    }

    &.corn {
      &::before {
        content: 'CS';
      }
    }

    &.soy {
      &::before {
        content: 'SS';
      }
    }

    &.wheat {
      &::before {
        content: 'WS';
      }
    }
  }

  &.icon-retail {
    background-color: $lightest;

    &.icon-large {
      font-size: 16px;
      height: 24px;
      width: 24px;
      padding-top: 1px;
    }

    &.corn {
      &::before {
        content: 'CR';
      }
    }

    &.soy {
      &::before {
        content: 'SR';
      }
    }

    &.wheat {
      &::before {
        content: 'WR';
      }
    }
  }
}

.progress.progress-disabled {
  background-color: #ddd;
  opacity: 0.7;

  div[class*='progress-bar-'] {
    color: #666;
    background-color: #bbb;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.form-control.ui-select-search {
  width: 100% !important;
}

thead th.sortable {
  cursor: pointer;
}

tbody td a.lot-link {
  color: #222;

  &:visited,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: #337ab7;
  }
}

.bin {
  padding: 5px;

  &-content {
    padding: 30px 8px 8px;
    border: 1px solid #aaa;
    height: 125px;
    background-color: #fff;
    transition: all 250ms ease;
    font-size: 12px;
    position: relative;

    .lot-link {
      font-size: 13px;
      font-weight: bold;
      white-space: nowrap;

      a {
        span {
          color: #222;
        }

        &:visited,
        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }

        &:hover span {
          color: #337ab7;
        }
      }
    }

    .bin-amounts-summary {
      margin-top: 10px;
      font-size: 14px;
      white-space: nowrap;

      & > strong:nth-child(2) {
        margin-left: 12px;
      }

      & > i {
        cursor: pointer;
      }
    }

    &-corn {
      background-color: rgba($muted-green, 0.3) !important;

      .bin-heading > div {
        background-color: rgba($muted-green, 0.3);

        &:last-child {
          background-color: rgba($muted-green, 0.8);

          span:last-child {
            color: darken($muted-green, 20%);
          }
        }
      }
    }

    &-wheat {
      background-color: rgba($muted-yellow, 0.3) !important;

      .bin-heading > div {
        background-color: rgba($muted-yellow, 0.3);

        &:last-child {
          background-color: rgba($muted-yellow, 0.8);

          span:last-child {
            color: darken($muted-yellow, 20%);
          }
        }
      }
    }

    &-soybeans,
    &-soy {
      background-color: rgba($muted-blue, 0.3) !important;

      .bin-heading > div {
        background-color: rgba($muted-blue, 0.3);

        &:last-child {
          background-color: rgba($muted-blue, 0.8);

          span:last-child {
            color: darken($muted-blue, 20%);
          }
        }
      }
    }

    &-clean {
      background-color: rgba($dark-gray, 0.1) !important;

      .bin-heading > div {
        background-color: rgba($dark-gray, 0.1);

        &:last-child {
          background-color: rgba($dark-gray, 0.3);

          span:last-child {
            color: rgba($dark-gray, 0.8);
          }
        }
      }
    }

    &-dirty {
      background-color: lighten($light-brown, 10%) !important;

      b {
        color: rgba($brown, 0.3);
      }

      .bin-heading > div {
        background-color: rgba($brown, 0.1);

        &:last-child {
          background-color: rgba($brown, 0.2);

          span:last-child {
            color: rgba($brown, 1);
          }
        }
      }
    }

    &-restricted {
      .bin-heading > div {
        &:first-child {
          background-color: $red;
        }
      }
    }

    &-offline {
      .bin-heading > div {
        opacity: 0.5;

        &:last-child {
          opacity: 0.5;

          span:last-child {
            opacity: 0.5;
          }
        }
      }

      .bin-body > div {
        opacity: 0.5;

        &:last-child {
          opacity: 1;
        }
      }
    }

    &-auto-height {
      height: auto;
    }

    &:hover {
      border: 1px solid #666;
    }
  }

  div[class*='col-xs-'] {
    height: 22px;
  }

  &-transfer {
    text-align: center;
    padding: 22px 10px;
    color: rgba(#fff, 0.8);
    border: 1px transparent dashed;

    &.over {
      border: 1px $white dotted;
    }
  }

  &-heading {
    display: table;
    table-layout: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    > div {
      display: table-cell;
      padding: 2px 5px;
      font-size: 14px;
      white-space: nowrap;
      vertical-align: middle;

      &:first-child {
        background-color: #222;
        color: #fff;
        width: 130px;
      }

      &.bin-capacity {
        right: 0;
        color: #222;
        text-align: right;

        span:first-child {
          font-weight: bold;
          font-size: 16px;
        }

        span:last-child {
          font-size: 12px;
        }
      }
    }
  }
}

.instruction {
  font-weight: bold;

  .number {
    color: #888;
  }
}

.inbred {
  display: inline-flex;
  width: 100%;

  .status {
    width: 25px;
  }

  .col-md-11 {
    padding: 0px;
  }
}

span.accordionHeader.pull-right {
  font-size: 13px;
}

/* CAPPS Sales Forecast */

.no-padding {
  padding-left: 0 !important;
}

.checkbox {
  .fa {
    margin-left: -20px;
    font-size: 1.2em;
    color: #ccc;
    @include border-radius(3px);
  }

  .fa-check-square {
    color: $blue;
  }

  .fa-square {
    color: #ccc;
  }
}

.radio {
  cursor: pointer;
}

.flex-table-row.selectedRow {
  background-color: rgba($primary, 0.25);
}

.panel-success > .panel-heading {
  background-color: rgba($primary, 0.25);
  border-color: $primary;
}

.panel {
  border: none;

  .container-fluid {
    padding: 10px;
  }

  .panel-heading {
    padding: 10px;
  }

  &.panel-no-padding {
    > .panel-body {
      padding: 0 !important;

      .alert {
        @include border-radius(0);
        margin-bottom: 0;
      }

      .table {
        margin: 0;
      }
    }
  }
}

.panel-primary > .panel-heading {
  + .panel-collapse > .panel-body {
    border-top-color: darken($primary, 6%);
  }
}

.panel {
  border: none;

  .panel-primary {
    .panel-heading {
      background-color: $primary !important;
      border: none;
    }

    .panel-heading + .panel-collapse {
      .panel-body {
        border-top-color: transparent;
        padding: 18px;
      }
    }
  }

  .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #222;
    font-weight: 600;

    > span {
      color: rgba(black, 0.5);
      font-size: 14px;
      vertical-align: top;
      margin: 1px 5px;

      &.header-span {
        vertical-align: middle;
        font-size: 16px;
      }
    }
  }
}

input:focus,
textarea:focus,
a:focus,
span:focus,
i:focus {
  outline: none;
}

h1.title {
  margin-bottom: 30px;
  margin-top: -8px;
  border-bottom: 1px #666 solid;
  padding-bottom: 9px;
}

.number {
  display: inline;
  text-align: right;
}

//prod plan page
.fa-filter,
.fa-history,
.fa-chevron-circle-right,
.fa-chevron-circle-left,
.fa-pencil,
.fa-remove,
.fa-eye {
  cursor: pointer;
}

.grayed-out-text {
  white-space: nowrap;
  color: #888;
  font-size: 0.9em;
}
