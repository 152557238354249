.table {
  font-size: 14px;
  td {
    font-weight: normal;
  }
  thead {
    th {
      .thead-popover {
        min-width: initial;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        max-width: 200px;

        .arrow {
          display: none;
        }
      }

      button.cust-btn {
        padding: 0;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
  tbody.highlighted {
    tr {
      background-color: lighten($blue, 42%);
      td,
      th {
        border-color: lighten($blue, 30%);
      }
      &:hover,
      th {
        background-color: lighten($blue, 40%);
      }
    }

    &.gray {
      tr {
        background-color: rgba($light-gray, 0.5);
        td,
        th {
          border-color: $light-gray;
        }
        &:hover,
        th {
          background-color: rgba($light-gray, 0.8);
        }
      }
    }

    &.corn {
      tr {
        background-color: lighten($muted-green, 35%);
        td,
        th {
          border-color: lighten($muted-green, 30%);
        }
        &:hover,
        th {
          background-color: lighten($muted-green, 33%);
        }
      }
    }
    &.soybeans {
      tr {
        background-color: lighten($muted-blue, 25%);
        td,
        th {
          border-color: lighten($muted-blue, 20%);
        }
        &:hover,
        th {
          background-color: lighten($muted-blue, 23%);
        }
      }
    }
    &.wheat {
      tr {
        background-color: lighten($muted-yellow, 25%);
        td,
        th {
          border-color: lighten($muted-yellow, 20%);
        }
        &:hover,
        th {
          background-color: lighten($muted-yellow, 23%);
        }
      }
    }
  }

  thead.highlighted {
    tr th {
      background-color: lighten($blue, 35%);
    }
    &.corn {
      tr th {
        background-color: lighten($muted-green, 25%);
      }
    }
    &.soybeans {
      tr th {
        background-color: lighten($muted-blue, 15%);
      }
    }
    &.wheat {
      tr th {
        background-color: lighten($muted-yellow, 15%);
      }
    }
  }

  &.compact {
    thead,
    tbody,
    tfoot {
      tr {
        th,
        td {
          padding: 4px 8px;
        }
      }
    }
  }

  &.table-nowrap {
    tr td {
      white-space: nowrap;
      .ui-select-container {
        display: inline-block;
        width: 80%;
        max-width: 80%;
      }
    }
  }
  td.actions,
  th.actions {
    a:not(.btn) {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
    a.btn {
      margin-left: 0px;
    }
  }
  &.treatment {
    .ui-select-container {
      .btn:not(.ui-select-match-item):not(.btn-link),
      .form-control {
        padding: 5px 8px;
        height: auto;
        min-height: 26px;
        font-size: 13px;
        width: 253px;
        height: 32px;
      }
      .btn-link {
        line-height: 1;
      }
    }
  }
  .ui-select-container {
    .btn:not(.ui-select-match-item):not(.btn-link),
    .form-control {
      padding: 3px 8px;
      height: auto;
      min-height: 26px;
      font-size: 13px;

      .ui-select-match-text {
        width: 70%;

        span {
          white-space: normal;
        }
      }
    }
    .btn-link {
      line-height: 1;
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -9px;
    }
  }
  .form-group.table-dropdown-normal {
    .ui-select-container {
      margin-bottom: 0 !important;
      .btn:not(.ui-select-match-item):not(.btn-link),
      .form-control {
        min-height: 34px;
        font-size: 14px;
        padding: 6px 12px;
      }
    }
  }
  tr.success {
    td,
    th {
      background-color: rgba($primary, 0.3) !important;
      border-color: rgba($muted-green, 0.2);
    }
  }
  tr.dirty {
    td,
    th {
      background-color: rgba($brown, 0.1) !important;
      border-color: rgba($light-brown, 0.2);
    }
  }
  tr.active {
    th,
    td {
      background-color: #e6e6e6 !important;
    }
  }
  tr.restricted {
    td:first-child {
      background-color: $red !important;
      color: #fff;
    }
  }
  tr.offline td {
    opacity: 0.5;
    &:last-child {
      opacity: 1;
    }
  }
  tr.no-results {
    td {
      text-align: center;
      height: 50px;
      vertical-align: middle;
      font-weight: lighter;
    }
  }
  .gray-cell {
    background-color: #eee;
  }
  &.table-align-middle {
    td,
    th {
      vertical-align: middle;
    }
  }
  tfoot {
    tr:first-child {
      th,
      td {
        border-top-width: 2px;
      }
    }
  }
  &.table-fixed {
    table-layout: fixed !important;
  }
  &.table-text-right {
    th,
    td {
      text-align: right;
    }
  }
  &.table-no-border {
    th,
    td {
      border-top: none !important;
    }
  }
  &.table-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  .fa-check-square,
  .fa-square {
    color: #ccc;
    @include border-radius(3px);
  }
  tbody.table-row-group:nth-child(even) > tr {
    background-color: rgba($amber, 0.1);
    &:hover {
      background-color: rgba($amber, 0.2);
    }
    th,
    td {
      border-color: rgba($muted-blue, 0.2);
    }
  }
  tbody > tr.selectedRow {
    background-color: rgba($primary, 0.25);
    .fa-check-square {
      color: darken($primary, 10%);
    }
    &:hover {
      background-color: rgba($primary, 0.35) !important;
    }
    th,
    td {
      border-color: $primary;
    }
  }
  &.table-striped > tbody {
    tr.selectedRow:nth-of-type(2n + 1) {
      background-color: rgba($primary, 0.25);
      td {
        background-color: rgba($primary, 0.25);
      }
    }
    tr.selectedRow:nth-of-type(even) {
      background-color: rgba($primary, 0.3);
      td {
        background-color: rgba($primary, 0.3);
      }
    }
  }
  .run-builder-sm-tb {
    font-weight: 400;
    text-align: center;
    background-color: #e8e8e8;
    color: #000;
    height: 30px;
    width: 65px;
  }
  .run-builder-header {
    font-weight: 600;
    font-size: 16px;
  }
}

td {
  &.has-error input {
    color: #f44242;
    border-color: #f44242;
    background-color: rgba(255, 0, 0, 0.2);
  }
  input[type='checkbox'] {
    width: auto;
    height: auto;
    border: none;
    display: inline-block;

    &.right-space {
      margin-right: 5px;
    }
  }
}

@media (min-width: 769px) {
  .table {
    td,
    th {
      &.actions {
        width: 30px;
        min-width: 30px;
        max-width: 150px;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}

@media (max-width: 768px) {
  .caption {
    display: none;
  }

  .run-builder-sm-tb {
    font-weight: 400;
    text-align: center;
    background-color: #e8e8e8;
    color: #000;
    height: 30px;
    width: 65px;
    border-radius: 4px;
  }
  .panel.panel-no-padding {
    table.table-collapse > tbody > tr {
      border-left: none !important;
      border-right: none !important;
    }
  }

  table.table-collapse {
    display: block;
    background-color: transparent !important;
    thead,
    tbody,
    tfoot,
    th,
    td,
    tr {
      display: block;
      border: none;
    }
    tr.success {
      border: 1px solid $muted-green;
      td,
      th {
        border-color: #fff;
      }
    }
    > thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    > tfoot {
      tr {
        th {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }
      }
    }
    > tbody,
    > tfoot {
      > tr {
        border: 1px solid #ddd;
        margin-bottom: 5px;
        background-color: #fff !important;
        &:last-child {
          margin-bottom: 0;
        }
        > th {
          font-size: 1.2em;
          text-align: left;
          background-color: rgba(#000, 0.05);
          border-top: none;
        }
        > td {
          position: relative;
          border-top: 1px solid #fff;
          text-align: left;
          padding: 4px 8px;
          width: 100% !important;
          &.actions {
            text-align: right !important;
          }
          span[class*='visible-'] {
            display: inline-block !important;
          }
          &:nth-of-type(odd) {
            background-color: #f9f9f9;
          }
          > input.form-control {
            width: 50%;
            display: inline-block;
          }
          .checkbox {
            margin-top: 5px;
            margin-bottom: 5px;
          }
          > .ui-select-container {
            display: inline-block;
            width: 40% !important;
          }
          > .ui-select-container.ui-select-container {
            display: inline-block;
            width: 100% !important;
          }
          > .form-inline {
            display: inline-block;
            width: 48%;
            text-align: left;
            .checkbox,
            .radio {
              display: inline-block;
            }
          }
        }
        td:before {
          content: attr(data-header);
          position: relative;
          display: inline-block;
          width: 50%;
          padding-right: 10px;
          font-size: 13px;
          font-weight: 600;
          color: #222;
          text-align: left;
        }
      }
    }
    > tfoot {
      > tr {
        > td {
          background-color: lighten($blue, 42%) !important;
          border-top: none;
        }
        > td,
        > td:before {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }
  .ps-modal {
    table.table-collapse {
      > tbody,
      > tfoot {
        > tr {
          border: 1px solid #000;
          background-color: #444 !important;
          > th {
            background-color: rgba(#000, 0.25);
          }
          > td:nth-of-type(odd) {
            background-color: rgba(#fff, 0.05);
          }
          td:before {
            color: #aaa;
          }
        }
      }
      > tfoot {
        > tr {
          td {
            background-color: darken($gray-blue, 25%) !important;
          }
          > td:before {
            color: #fff;
          }
        }
      }
    }
  }
}

.div-table {
  display: table;
  width: 100%;
  &-fixed {
    table-layout: fixed;
  }
  > .div-table-header {
    display: table-header-group;
    position: relative;
    .div-table-cell {
      font-weight: bold;
    }
  }
  > .div-table-body {
    display: table-row-group;
    position: relative;
  }
  > .div-table-footer {
    display: table-footer-group;
    position: relative;
    .div-table-cell {
      font-weight: bold;
    }
  }
  .div-table-row {
    display: table-row;
    position: relative;
  }
  .div-table-cell {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding: 5px 8px;
  }
  &-condensed {
    .div-table-cell {
      padding: 2px 4px;
    }
  }
  &.full-height {
    height: 100%;
  }
}

@media (max-width: 320px) {
  .div-table {
    &.div-table-collapse {
      display: block;
      .div-table-row,
      .div-table-cell,
      .div-table-header,
      .div-table-footer,
      .div-table-body {
        display: block;
      }
    }
  }
}

.table-col {
  overflow-x: auto;
}
