@import 'colors';

// Variables
// --------------------------------------------------
$header-height: 64px;
$header-height-phone: 56px;
$page-header-height: 64px;
$page-header-height-phone: 56px;
$well-header-height: 36px;

$mat-progress-bar-height: 4px;

$mat-card-title-height: 48px;

$title-bar-height: 64px;
$title-bar-height-phone: 64px;

//== Sidebar
//
//##

// Basics of Sidebar
$sidebar-width: 250px !default;
$tight-sidebar-width: 60px;
$sidebar-background: #212121;
$sidebar-active: #424242;
$sidebar-active-text: #fafafa;
$sidebar-icon: #aeaeae;

// MODAL Building Blocks
$modal-block-margin: 20px;
$modal-block-margin-mobile: 5px;

// Modal-related variables
$modal-padding-x: 20px;
$modal-padding-y: 55px;
$modal-header-height: 55px;
$modal-footer-height: 76px;

$modal-height-desktop: 80vh;
$modal-height-mobile: 100vh;

//
//## Gray and brand colors.
$white: #fff;
$black: #000;
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee

//** CUSTOM COLORS **
$black-87-opacity: rgba($black, 0.87);
$white-87-opacity: rgba($white, 0.87);
$black-12-opacity: rgba($black, 0.12);
$white-12-opacity: rgba($white, 0.12);
$black-6-opacity: rgba($black, 0.06);
$white-6-opacity: rgba($white, 0.06);

$gray-1-opacity: #fafafa;
$gray-2-opacity: #efefef;
$gray-4-opacity: #e0e0e0;
$gray-5-opacity: #bdbdbd;
$gray-6-opacity: #9e9e9e;
$gray-7-opacity: #757575;
$gray-8-opacity: #616161;
$gray-9-opacity: #424242;
$gray-10-opacity: #212121;

$shell-background: #e5e5e5;

$wheat-yellow: #f3c402;
$soybean-blue: #cbdde6;
$corn-green: #cadab6;
$label-text-color: #909090;

$link-hover-decoration: underline !default;

// ** ENVIRONMENT COLORS **
$env-local: #8bc34a;
$env-dev: #a5c9f6;
$env-qa: #b85c28;
$env-prod: #bcbcbc;
$env-preview: #ad3535;

// LABTRACKER COLORS
$lt-blue: #3a70af;
$lt-red: #af3a3a;
$lt-yellow: #f1c232;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-family: 'Open Sans', sans-serif;

//== Media Queries
// When managing these breakpoints, the same changes need to be made to the layout.component.ts file,
// in the _getBreakpointEntities function and the breakpoints.interface.ts file should be updated as well based on the changes being made here.

@mixin breakpoint($points...) {
  @each $point in $points {
    @if $point == xl {
      @media screen and (min-width: 1920px) {
        @content;
      }
    } @else if $point == gt-lg {
      @media screen and (min-width: 1920px) {
        @content;
      }
    } @else if $point == lg {
      @media screen and (min-width: 1280px) and (max-width: 1919px) {
        @content;
      }
    } @else if $point == gt-md {
      @media screen and (min-width: 1280px) {
        @content;
      }
    } @else if $point == md {
      @media screen and (min-width: 960px) and (max-width: 1279px) {
        @content;
      }
    } @else if $point == lt-md {
      @media screen and (max-width: 960px) {
        @content;
      }
    } @else if $point == lt-sm {
      @media screen and (max-width: 768px) {
        @content;
      }
    } @else if $point == gt-sm {
      @media screen and (min-width: 960px) {
        @content;
      }
    } @else if $point == ipad-pro {
      @media screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        @content;
      }
    } @else if $point == ipad-pro-landscape {
      @media screen and (min-width: 1024px) and (max-device-width: 1366px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
        @content;
      }
    } @else if $point == ipad-landscape {
      @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
      }
    } @else if $point == sm {
      @media screen and (min-width: 600px) and (max-width: 959px) {
        @content;
      }
    } @else if $point == gt-xs {
      @media screen and (min-width: 600px) {
        @content;
      }
    } @else if $point == xs {
      // iPhone 7+/8+ //
      @media screen and (max-width: 599px) {
        @content;
      }
    } @else if $point == xs-max {
      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
        @content;
      }
    } @else if $point == xs-landscape {
      // use for iphone 6/7+ landscape
      @media screen and (min-width: 640px) and (max-width: 812px) and (orientation: landscape) {
        @content;
      }
    } @else if $point == xxs-landscape {
      // use for iphone 6/7 landscape
      @media screen and (min-width: 569px) and (max-width: 667px) and (orientation: landscape) {
        @content;
      }
    } @else if $point == xxxs-landscape {
      // use for iphone 5 landscape
      @media screen and (min-width: 558px) and (max-width: 568px) and (orientation: landscape) {
        @content;
      }
    } @else if $point == xxs {
      // iPhone 7/8 //
      @media screen and (max-width: 375px) {
        @content;
      }
    } @else if $point == xxxs {
      // iPhone 5 //
      @media screen and (max-width: 320px) {
        @content;
      }
    } @else if $point == tw-max-sm {
      @media (max-width: 640px) {
        @content;
      }
    } @else if $point == tw-sm {
      @media (min-width: 640px) {
        @content;
      }
    } @else if $point == tw-max-md {
      @media (min-width: 641px) and (max-width: 768px) {
        @content;
      }
    } @else if $point == tw-md {
      @media (min-width: 768px) {
        @content;
      }
    } @else if $point == tw-lg {
      @media (min-width: 1024px) {
        @content;
      }
    } @else if $point == tw-xl {
      @media (min-width: 1280px) {
        @content;
      }
    } @else if $point == tw-xxl {
      @media (min-width: 1536px) {
        @content;
      }
    }
  }
}

@mixin mat-icon-size($size) {
  font-size: $size;
  height: $size;
  width: $size;
}

@mixin ps-progress-spinner-size($size: 30px) {
  display: flex;
  flex-direction: column;
  justify-content: center;

  ::ng-deep .mat-progress-spinner {
    height: $size !important; // important here is needed to override the material element level styles
    width: $size !important; // important here is needed to override the material element level styles

    svg {
      height: $size !important; // important here is needed to override the material element level styles
      width: $size !important; // important here is needed to override the material element level styles
    }
  }
}

// Atoms (inspired by tailwindcss)
:root {
  --ring-inset: ;
  --ring-offset-width: 0px;
  --ring-color: currentColor;
  --ring-opacity: 1;
  --ring-offset-color: white;
  --space-y-reverse: 0;
  --space-x-reverse: 0;
  --shadow: 0 0 #0000;
  --border-style: solid;
}
@mixin ring-1() {
  box-shadow:
    0 0 0 var(--ring-offset-width) var(--ring-offset-color),
    var(--ring-inset) 0 0 0 calc(1px + var(--ring-offset-width)) var(--ring-color);
}
@mixin ring-2() {
  box-shadow:
    0 0 0 var(--ring-offset-width) var(--ring-offset-color),
    var(--ring-inset) 0 0 0 calc(2px + var(--ring-offset-width)) var(--ring-color);
}
@mixin ring() {
  box-shadow:
    0 0 0 var(--ring-offset-width) var(--ring-offset-color),
    var(--ring-inset) 0 0 0 calc(3px + var(--ring-offset-width)) var(--ring-color);
}
@mixin ring-offset-shell() {
  --ring-offset-color: #{$shell-background};
}
@mixin ring-red-500() {
  --ring-color: #{rgba($red-500, var(--ring-opacity))};
}
@mixin ring-opacity-50() {
  --ring-opacity: 0.5;
}
@mixin ring-offset-1() {
  --ring-offset-width: 1px;
}
@mixin ring-offset-2() {
  --ring-offset-width: 2px;
}
@mixin ring-offset-4() {
  --ring-offset-width: 4px;
}
@mixin ring-offset-8() {
  --ring-offset-width: 8px;
}

@mixin space-x-($amount) {
  > :not([hidden]) ~ :not([hidden]) {
    margin-right: calc(#{tw-number($amount)} * calc(var(--space-x-reverse)));
    margin-left: calc(#{tw-number($amount)} * calc(1 - var(--space-x-reverse)));
  }
}

@mixin space-y-($amount) {
  > :not([hidden]) ~ :not([hidden]) {
    margin-top: calc(#{tw-number($amount)} * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(#{tw-number($amount)} * calc(var(--space-y-reverse)));
  }
}

@mixin font-semibold() {
  font-weight: 600;
}
@mixin font-bold() {
  font-weight: 700;
}

@mixin text-sm() {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
@mixin text-base() {
  font-size: 1rem;
  line-height: 1.5rem;
}
@mixin text-lg() {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
@mixin text-xl() {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
@mixin text-2xl() {
  font-size: 1.5rem;
  line-height: 2rem;
}
@mixin text-3xl() {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
@mixin text-4xl() {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
@mixin text-5xl() {
  font-size: 3rem;
  line-height: 1;
}
@mixin text-6xl() {
  font-size: 3.75rem;
  line-height: 1;
}

@mixin shadow-md() {
  --shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--ring-offset-shadow, 0 0 #0000), var(--ring-shadow, 0 0 #0000), var(--shadow);
}

@function tw-number($number) {
  @if ($number == '0') {
    @return 0px;
  } @else if ($number == '0.5') {
    @return 0.125rem;
  } @else if ($number == '1') {
    @return 0.25rem;
  } @else if ($number == '1.5') {
    @return 0.375rem;
  } @else if ($number == '2') {
    @return 0.5rem;
  } @else if ($number == '4') {
    @return 1rem;
  } @else if ($number == '5') {
    @return 1.25rem;
  } @else if ($number == '6') {
    @return 1.5rem;
  } @else if ($number == '7') {
    @return 1.75rem;
  } @else if ($number == '8') {
    @return 2rem;
  } @else if ($number == '14') {
    @return 3.5rem;
  } @else if ($number == '16') {
    @return 4rem;
  } @else if ($number == '20') {
    @return 5rem;
  } @else if ($number == '24') {
    @return 6rem;
  } @else if ($number == '56') {
    @return 14rem;
  } @else if ($number == '1/3') {
    @return 33.333333%;
  } @else if ($number == '1/2') {
    @return 50%;
  } @else if ($number == '2/3') {
    @return 66.666667%;
  } @else if ($number == '1/4') {
    @return 25%;
  } @else if ($number == '3/4') {
    @return 75%;
  } @else if ($number == '4/12') {
    @return 33.333333%;
  } @else if ($number == '5/12') {
    @return 41.666667%;
  } @else if ($number == '7/12') {
    @return 58.333333%;
  } @else if ($number == '8/12') {
    @return 66.666667%;
  } @else if ($number == '9/12') {
    @return 75%;
  } @else if ($number == '10/12') {
    @return 83.333333%;
  } @else if ($number == '11/12') {
    @return 91.666667%;
  }
}

@mixin mb-($marginBottom) {
  margin-bottom: tw-number($marginBottom);
}

@mixin mt-($marginTop) {
  margin-top: tw-number($marginTop);
}

@mixin ml-($marginLeft) {
  margin-left: tw-number($marginLeft);
}

@mixin p-($padding) {
  padding: tw-number($padding);
}

@mixin pb-($padding) {
  padding-bottom: tw-number($padding);
}

@mixin pt-($paddingTop) {
  padding-top: tw-number($paddingTop);
}

@mixin px-($paddingHorizontal) {
  padding-left: tw-number($paddingHorizontal);
  padding-right: tw-number($paddingHorizontal);
}

@mixin py-($paddingVertical) {
  padding-top: tw-number($paddingVertical);
  padding-bottom: tw-number($paddingVertical);
}

@mixin pl-($paddingLeft) {
  padding-left: tw-number($paddingLeft);
}

@mixin pr-($paddingRight) {
  padding-right: tw-number($paddingRight);
}

@mixin w-($width) {
  width: tw-number($width);
}

@mixin h-($height) {
  height: tw-number($height);
}

@mixin rounded-md {
  border-radius: 0.375rem;
}

@mixin rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

@mixin rounded-lg {
  border-radius: 0.5rem;
}

@mixin rounded-b-md {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

@mixin border {
  border-width: 1px;
  border-style: var(--border-style);
}

@mixin hide-wrapper {
  .mat-form-field-wrapper {
    padding-bottom: 1rem;
  }
  .mat-form-field-underline {
    bottom: 1rem;
  }
}

@mixin mat-chips-small {
  // make the chips smaller
  mat-chip.mat-standard-chip {
    margin: 1px;
    font-size: 1rem;
    min-height: 2.25rem;
    padding-right: 4px;
    padding-left: 9px;
    &.st-lot-assignment-table__more-chips {
      padding-left: 4px;
      padding-right: 5px;
      font-weight: 700;
    }
    .mat-chip-remove {
      width: 16px;
      height: 16px;
      margin-left: 2px;
      margin-bottom: 2px;
    }
  }
}
