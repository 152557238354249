#treatmentInfo {
  .btn-group {
    padding-top: 5px;
  }
  .panel-header-row {
    padding: 10px 0 10px 0;
    background: rgba(0, 0, 0, 0.03);
  }
  .panel-row {
    margin: 0;
  }
  .panel-body {
    padding-top: 0;
  }
  .panel-heading {
    .container-fluid {
      padding: 0;
    }
    .panel-title {
      span {
        font-size: 14px;
        color: #fff;

        span.badge {
          background: #fff;
          color: #337ab7;
        }
      }
    }
  }

  .table-auto-calc {
    padding: 0;
    margin-bottom: 0px;
    input {
      width: 100%;
      height: 25px;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    tr {
      height: 35px;
    }
    th {
      text-align: right;
      border-top: none;
      color: #000;
      label {
        margin-bottom: 0px;
      }
      vertical-align: middle;
    }
    td {
      text-align: center;
      border-top: none;
      color: #555;
      label {
        margin-bottom: 0px;
      }
      vertical-align: middle;
    }

    .percent-input {
      width: 70%;
    }
    .percent-input + label {
      padding-left: 5px;
    }

    .bionik-group {
      vertical-align: middle;
      font-weight: 400;
      color: #676666;
    }
    .colorant-group {
      vertical-align: middle;
      color: #676666;
      font-weight: 400;
    }
    .optimized-header {
      color: #676666;
    }

    .sub-total-lbs {
      background: rgba(188, 200, 206, 0.5);
      padding: 0 5px 0 10px;
    }

    .ui-select-container,
    .ui-select-choices {
      width: 100% !important;
      min-width: 70px;
    }

    .ui-select-match-text {
      text-align: center;
      width: 100%;
    }
  }

  .table-parent {
    width: auto;
    margin: 0 auto;
  }

  .table-uniform {
    min-width: 130px;
  }

  .prescription-container {
    margin: 0;
    overflow-x: auto;
  }

  .table-colorant {
    tr:first-child {
      th {
        border-top: none;
      }
      td {
        border-top: none;
      }
    }
  }
  .header {
    font-weight: 600;
    border-bottom: 0;
    letter-spacing: 1px;
    vertical-align: middle;
    background-color: #bcbcbc;
    text-transform: uppercase;
    color: #15140e;
    padding: 5px;
    text-align: center;

    label {
      margin: 0;
    }
  }
}
